import { DateTime } from 'luxon';
import { z } from 'zod';

export const schema = z.object({
  startDate: z
    .custom<DateTime>()
    .refine(val => val && val.isValid, {
      message: 'Invalid date',
    })
    .refine(val => val >= DateTime.now().startOf('day'), {
      message: 'Start date must be in the future',
    }),
});

export type FormValues = z.infer<typeof schema>;
