import React, { useContext, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { DrawerOptions } from './types';

type SideDrawerContextType = {
  drawerId: string | null;
  openDrawer: (id: string, options?: DrawerOptions) => void;
  closeDrawer: () => void;
};

const SideDrawerContext = React.createContext<
  SideDrawerContextType | undefined
>(undefined);

type UseSideDrawer = {
  id: string;
  open: (options?: DrawerOptions) => void;
  close: () => void;
};

export function useSideDrawer(): UseSideDrawer {
  const drawer = useContext(SideDrawerContext);
  const [drawerId] = useState(() => uuidV4());

  if (!drawer) {
    throw new Error('use useDrawer under SideDrawerContext');
  }

  return {
    id: drawerId,
    open: (options?: DrawerOptions) => {
      drawer.openDrawer(drawerId, options);
    },
    close: drawer.closeDrawer,
  };
}

export default SideDrawerContext;
