import { Profile, RegistrationResponse } from '@just-insure/api';
import { gql } from 'graphql-request';
import { DateTime } from 'luxon';
import { Paginated, QueryOptions, Trip, User } from '../../../types/interfaces';
import { fetchGraphQLQuery } from '../../graphql/service';
import {
  Query,
  QuoteOverview,
  Transaction as TransactionQL,
  User as UserGraphQL,
  Wallet,
  DisableAutomaticPaymentsForUserInput,
} from '../../graphql/types';
import { setDateTimezone, setUserTimezone } from '../../helpers/convertDate';
import { api } from '../httpService';
import paginatedQueryString from '../paginatedQueryString';
import { mapGraphPolicies, UserPolicy } from './policyService';
import { Mutation, UpdateUserEmailInput } from '../../graphql/api';

const columnMapping = {
  name: 'firstName',
  age: 'dob',
};

export type Quote = Omit<QuoteOverview, 'issueDate' | 'validUntil'> & {
  issueDate: string;
  validUntil: string;
};

export type Transaction = Omit<TransactionQL, 'createdAt'> & {
  createdAt: DateTime;
};

export type AutoPaySettings = {
  isApprovalEnabled: boolean;
  isEnabled: boolean;
  amount: number;
  threshold: number;
};

export type UserGraph = Omit<
  UserGraphQL,
  'emails' | 'policies' | 'transactions' | 'wallet' | 'balance'
> & {
  policies: UserPolicy[];
  transactions: Transaction[];
  quoteOverviews: Quote[];
  balance?: number;
  funds?: number;
  autoPay: AutoPaySettings;
};

export const getUserList = async (
  query: QueryOptions,
  search: string,
): Promise<Paginated<User>> =>
  await api<Paginated<User>>(
    'get',
    `/api/users?${paginatedQueryString(query, search, columnMapping)}`,
  );

const getDriverLicenseNumber = async (
  userId: string,
): Promise<string | undefined> => {
  const driverLicenseQuery = gql`
    query getDriverLicenseNumber($userId: ID!) {
      user(id: $userId) {
        profile {
          personalInfo {
            driverLicenseNumber
          }
        }
      }
    }
  `;

  const response = await fetchGraphQLQuery<Query>(driverLicenseQuery, {
    userId,
  });

  return response.user.profile?.personalInfo.driverLicenseNumber ?? undefined;
};

export const getUserDetails = async (userId: string) => {
  const [userData, driverLicenseNumber] = await Promise.all([
    api<User>('get', `/api/users/${userId}/details`),
    getDriverLicenseNumber(userId),
  ]);

  // We async call user details and graph so until we replace this service, we set timezone here
  setUserTimezone(userId, userData.timezone);

  return {
    ...userData,
    driverLicenseNumber,
    trips: userData.trips
      ? userData.trips?.map(t => mapUserTrips(userId, t))
      : [],
  };
};

const mapUserTrips = (userId: string, trip: Trip): Trip => {
  return {
    ...trip,
    start: setDateTimezone(trip.start, userId).toFormat('FF'),
    end: setDateTimezone(trip.end, userId).toFormat('FF'),
  };
};

export const getUserRegistrationProgress = async (userId: string) =>
  await api<RegistrationResponse>('get', `/api/users/${userId}/registration`);

export const saveUserDetails = async (userId: string, user: Profile) =>
  await api<Profile>('put', `/users/${userId}/profile`, user);

export const saveUserEmailV1 = async (userId: string, email: string) =>
  await api<void>('patch', `/users/${userId}`, { email });

export const saveUserEmailV2 = async (input: UpdateUserEmailInput) => {
  const mutation = gql`
    mutation updateUserEmail($input: UpdateUserEmailInput!) {
      updateUserEmail(input: $input) {
        email
      }
    }
  `;

  const response = await fetchGraphQLQuery<Mutation>(mutation, { input });

  return response.updateUserEmail.email!;
};

export const getUserGraphDetails = async (
  userId: string,
): Promise<UserGraph> => {
  const response = await fetchGraphQLQuery<Query>(
    gql`
      query getUserDetails($userId: ID!) {
        user(id: $userId) {
          profile {
            timezone
          }
          acv {
            accidents {
              amountOfLoss
              yearOccurred
              anyoneInjured
              atFault
            }
            claims {
              amountOfLoss
              yearOccurred
            }
            violations {
              yearOccurred
              violationType
            }
          }
          transactions {
            id
            transactionId
            amount
            activityType
            balance
            type
            reason
            createdAt
          }
          quoteOverviews {
            id
            price {
              packages {
                id
                title
                subtitle
                imageUrl
                coverageGroups {
                  id
                  name
                  summary
                  coverages {
                    id
                    name
                    limit {
                      title
                      value
                      options {
                        id
                        value
                        label
                        isDefault
                        premium {
                          title
                          value {
                            unit
                            amount
                          }
                        }
                      }
                    }
                    deductibles {
                      title
                      options {
                        id
                        value
                        label
                        isDefault
                        premium {
                          title
                          value {
                            unit
                            amount
                          }
                        }
                      }
                    }
                    premium {
                      title
                      value {
                        unit
                        amount
                      }
                    }
                  }
                }
                optionalCoverageGroups {
                  id
                  title
                  subtitle
                  imageUrl
                  coverageGroups {
                    id
                    name
                    coverages {
                      id
                      name
                      premium {
                        value {
                          unit
                          amount
                        }
                      }
                    }
                  }
                }
              }
              product {
                type
              }
            }
            selection {
              packages
              options
              optionalCoverageGroups
            }
            selectedPrice {
              pricePerDay
              pricePerMile
            }
            validUntil
            issueDate
            isAccepted
            acceptedAt
            status
          }
          policies {
            policyNumberDetails {
              value
            }
            term
            quoteId
            status
            cancellationDate
            cancellationReason
            timezone
            vehicles {
              vehicleDetails {
                id
                make
                model
                year
                vin
              }
              coverages {
                name
              }
            }
            totalPremium {
              perDay
              perMile
            }
            product {
              policyOptions {
                duration
              }
              odometerOptions {
                firstPolicy
                subsequentPolicy
              }
            }
            isRenewal
          }
          wallet {
            funds
            balance
            automaticPaymentConfig {
              minRefillAmount
              defaultRefillThreshold
            }
            automaticPayments {
              isApprovalEnabled
              isEnabled
              refill {
                amount
                threshold
              }
            }
          }
        }
      }
    `,
    {
      userId,
    },
  );
  setUserTimezone(userId, response!.user!.profile!.timezone);
  return {
    ...response.user!,
    policies: mapGraphPolicies(response.user!.policies),
    transactions: response
      .user!.transactions.map(
        (transaction: TransactionQL): Transaction => ({
          ...transaction,
          createdAt: setDateTimezone(transaction.createdAt, userId),
        }),
      )
      .sort(sortTransactionDateToByNewestToOldest),
    funds: response.user.wallet.funds,
    balance: response.user.wallet.balance,
    autoPay: getImprovedAutoPay(response.user!.wallet),
    quoteOverviews: response.user!.quoteOverviews.map(q => mapQuote(userId, q)),
  };
};

const mapQuote = (userId: string, quoteOverview: QuoteOverview): Quote => {
  return {
    ...quoteOverview,
    issueDate: setDateTimezone(quoteOverview.issueDate, userId).toFormat('FF'),
    validUntil: setDateTimezone(quoteOverview.validUntil, userId).toFormat(
      'FF',
    ),
    ...(quoteOverview.acceptedAt && {
      acceptedAt: setDateTimezone(quoteOverview.acceptedAt, userId).toFormat(
        'FF',
      ),
    }),
  };
};

export const resetUserOdometer = async (userId: number) => {
  const queryString = gql`
    mutation resetOdometerWorkflow($userId: Int!) {
      resetOdometerWorkflow(userId: $userId)
    }
  `;
  return fetchGraphQLQuery(queryString, { userId });
};

export const disableAutoPayForUser = async (userId: number) => {
  const mutation = gql`
    mutation disableAutoPay($input: DisableAutomaticPaymentsForUserInput!) {
      disableAutomaticPaymentsForUser(input: $input) {
        isEnabled
      }
    }
  `;

  const input: DisableAutomaticPaymentsForUserInput = {
    userId,
  };

  return fetchGraphQLQuery(mutation, { input });
};

const sortTransactionDateToByNewestToOldest = (
  a: Transaction,
  b: Transaction,
) => {
  return a.createdAt.toSeconds() > b.createdAt.toSeconds() ? -1 : 1;
};

const getImprovedAutoPay = (graphqlResponse: Wallet): AutoPaySettings => {
  const { minRefillAmount, defaultRefillThreshold } =
    graphqlResponse.automaticPaymentConfig;
  return {
    isEnabled: !!graphqlResponse.automaticPayments?.isEnabled,
    isApprovalEnabled: !!graphqlResponse.automaticPayments?.isApprovalEnabled,
    amount:
      graphqlResponse.automaticPayments?.refill?.amount || minRefillAmount,
    threshold:
      graphqlResponse.automaticPayments?.refill?.threshold ||
      defaultRefillThreshold,
  };
};
