import React, { useState } from 'react';
import SideDrawerContext from './useSideDrawer';
import SideDrawerContent from './SiderDrawerContent';
import { DrawerOptions } from './types';

type Props = {
  children: React.ReactNode;
};

const SideDrawerProvider: React.FC<Props> = ({ children }) => {
  const [drawerId, setDrawerId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DrawerOptions | undefined>();

  function openDrawer(id: string, options?: DrawerOptions) {
    setOptions(options);
    setDrawerId(id);
    setOpen(true);
  }

  function closeDrawer() {
    setOpen(false);
  }

  function clearOnClose() {
    setDrawerId(null);
    setOptions(undefined);
  }

  return (
    <SideDrawerContext.Provider
      value={{
        drawerId,
        openDrawer,
        closeDrawer,
      }}
    >
      {children}
      <SideDrawerContent
        open={open}
        onClose={closeDrawer}
        options={options}
        onTransitionEnd={closed => {
          if (closed) {
            clearOnClose();
          }
        }}
      />
    </SideDrawerContext.Provider>
  );
};

export default SideDrawerProvider;
