import { UserPolicy } from '../../types';
import { addressToString } from '@helpers/addressHelpers';
import { PolicyCardType } from './types';

type Props = {
  policy: UserPolicy;
  policyCardType: PolicyCardType;
};

const PolicyInfo: React.FC<Props> = ({ policy }) => (
  <>
    <Row>
      <div>{policy.policyholder.name}</div>
      <div className="col-span-3">
        {addressToString(policy.policyholder.address)}
      </div>
    </Row>
    {policy.vehicles.map(({ year, make, model, vin }, index) => (
      <Row key={vin}>
        <div>Vehicle {index + 1}</div>
        <div>
          {year}, {make}, {model}
        </div>
        <div>{vin}</div>
      </Row>
    ))}
  </>
);

const Row: React.FC = ({ children }) => (
  <div className="grid grid-cols-4 gap-3 text-sm text-slate py-3 border-b border-cloudy-blue last:border-none">
    {children}
  </div>
);

export default PolicyInfo;
