import Drawer from '@componentsV2/Drawer';
import Summary from './Summary';

type Props = {
  userId: string;
  open: boolean;
  toggleDrawer: () => void;
};

const UserDrawer: React.FC<Props> = ({ userId, open, toggleDrawer }) => (
  <Drawer open={open} toggleDrawer={toggleDrawer}>
    <Summary userId={userId} />
  </Drawer>
);

export default UserDrawer;
