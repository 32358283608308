import { twMerge } from 'tailwind-merge';

type Props = {
  drawerOpen?: boolean;
  className?: string;
};

const PageContent: React.FC<Props> = ({ drawerOpen, children, className }) => (
  <div
    className={twMerge(
      'transition-padding duration-300 pl-45',
      drawerOpen ? 'pr-75' : '',
    )}
  >
    <div className={twMerge('p-6', className)}>{children}</div>
  </div>
);

export default PageContent;
