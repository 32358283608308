import { useGetUserPolicyListQuery } from '@graphql/api';
import ComponentLoading from '@componentsV2/ComponentLoading';
import { ErrorMessage } from '@componentsV2/Typography';
import getUserPolicyListSelector from './getUserPolicyListSelector';
import PolicyListContent from './PolicyListContent';

type Props = {
  userId: string;
  className?: string;
};

const PolicyList: React.FC<Props> = ({ userId, className }) => {
  const {
    isLoading,
    data: userPolicies,
    refetch: refetchUserPolicies,
  } = useGetUserPolicyListQuery(
    { userId },
    {
      queryKey: ['userPolicies', { userId }],
      select: getUserPolicyListSelector,
    },
  );

  return (
    <div className={className}>
      {(() => {
        switch (true) {
          case isLoading:
            return <ComponentLoading />;
          case !userPolicies:
            return <ErrorMessage>Error loading user policies</ErrorMessage>;
          default:
            return (
              <PolicyListContent
                userId={userId}
                policies={userPolicies!}
                onPolicyChange={refetchUserPolicies}
              />
            );
        }
      })()}
    </div>
  );
};
export default PolicyList;
