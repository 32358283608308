import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useField } from 'formik';
import React from 'react';

interface Props {
  readonly disabled?: boolean;
  readonly label?: string;
  readonly color?: 'primary' | 'secondary' | 'default';
  readonly name: string;
}

const FormCheckBox: React.FC<Props> = props => {
  const [field] = useField(props);
  const { disabled = false, label, color } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={field.onChange(field.name)}
          checked={field.value || ''}
          color={color}
        />
      }
      label={label}
    />
  );
};

export default FormCheckBox;
