import React, { useState, useEffect } from 'react';
import {
  useChargeUserMutation,
  ChargeInput,
  ChargeUserMutation,
} from '../../../../../../graphql/api';
import { Button, DialogContent } from '@material-ui/core';
import LoadingButton from '../../../../../../components/LoadingButton/LoadingButton';
import {
  StyledDialogTitle,
  StyledDialogActions,
} from '../../../../../../components/ConfirmationModalV2/ConfirmationModalV2.styled';
import { formatBalance } from '../../../../../../helpers/formatHelpers';
import { ErrorCode, logError } from '../../../../../../services/logging';
import ModalTable from './ModalTable';
import ModalError from '../../../../../../components/ModalError/ModalError';
import { UserWallet } from '../../common/types';

interface Props {
  userId: string;
  data: UserWallet;
  onClose: () => void;
}

const ModalContent: React.FC<Props> = ({ userId, data, onClose }) => {
  const [error, setError] = useState<string>();
  const { funds, isAutomaticPaymentsEnabled, isApprovalEnabled, token } = data;
  const isNegativeFunds = funds < 0;
  const amountToCharge = isNegativeFunds ? Math.abs(funds) : 0;

  const { isLoading, mutate } = useChargeUserMutation({
    onSuccess: (res: ChargeUserMutation) => {
      if (res.charge.__typename === 'ChargeFailure') {
        setError(res.charge.message);
      } else {
        onClose();
      }
    },
    onError: (err: Error) => {
      setError(err.message);
      logError(ErrorCode.ChargeUser, err);
    },
    useErrorBoundary: false,
  });

  useEffect(() => {
    if (!isNegativeFunds) {
      setError('Unable to take payment. The user balance is not outstanding.');
    } else if (!isAutomaticPaymentsEnabled && isApprovalEnabled) {
      setError(
        'Unable to take payment. The user has [auto payments disabled and payment approval enabled].',
      );
    } else if (!isAutomaticPaymentsEnabled) {
      setError(
        'Unable to take payment. The user has [auto payments disabled].',
      );
    } else if (isApprovalEnabled) {
      setError(
        'Unable to take payment. The user has [payment approval enabled].',
      );
    }
  }, [isNegativeFunds, isAutomaticPaymentsEnabled, isApprovalEnabled]);

  const onConfirm = () => {
    const input: ChargeInput = {
      userId: parseInt(userId, 10),
      amount: amountToCharge,
      token,
    };

    mutate({ input });
  };

  return (
    <>
      <StyledDialogTitle>Take a final payment</StyledDialogTitle>
      <DialogContent>
        <ModalTable
          isAutomaticPaymentsEnabled={isAutomaticPaymentsEnabled}
          isApprovalEnabled={isApprovalEnabled}
          formattedBalance={formatBalance(funds)}
        />
        {error! ? <ModalError error={error} /> : null}
      </DialogContent>
      <StyledDialogActions disableSpacing>
        <LoadingButton
          onClick={onConfirm}
          isLoading={isLoading}
          buttonType="confirm"
          disabled={
            !isAutomaticPaymentsEnabled || isApprovalEnabled || !isNegativeFunds
          }
        >
          {`Charge ${formatBalance(amountToCharge)}`}
        </LoadingButton>
        <Button onClick={onClose} variant="outlined" color="primary">
          Abort
        </Button>
      </StyledDialogActions>
    </>
  );
};

export default ModalContent;
