import { useGetUserSummaryQuery } from '@graphql/api';
import getUserSummarySelector from './getUserSummarySelector';
import SummaryContent from './SummaryContent';
import ComponentLoading from '@componentsV2/ComponentLoading';
import { ErrorMessage } from '@componentsV2/Typography';

type Props = {
  userId: string;
};

const Summary: React.FC<Props> = ({ userId }) => {
  const { isLoading, data: userSummary } = useGetUserSummaryQuery(
    { userId },
    {
      queryKey: ['userSummary', userId],
      select: getUserSummarySelector,
    },
  );

  if (isLoading) {
    return <ComponentLoading />;
  }

  if (!userSummary) {
    return <ErrorMessage>Error loading content</ErrorMessage>;
  }

  return <SummaryContent userSummary={userSummary} />;
};

export default Summary;
