import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import SideDrawerContext from '@componentsV2/SideDrawer/useSideDrawer';
import { getDrawerPortalElement } from '@componentsV2/SideDrawer/drawerPortalElement';

type Props = {
  id: string;
  children: React.ReactNode;
};

const SideDrawer: React.FC<Props> = ({ children, id }) => {
  const drawer = useContext(SideDrawerContext);
  if (!drawer) {
    throw new Error('use useDrawer under SideDrawerContext');
  }
  const element = getDrawerPortalElement();

  if (element && drawer.drawerId === id) {
    return createPortal(children, element);
  }

  return null;
};

export default SideDrawer;
