import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Table } from '../../../../components/Table';
import { columnDefs } from './columnDefs';
import { sortByYear } from '../common/helpers';
import { Claim } from '../../../../graphql/api';
import RowTemplate from './RowTemplate';
import ClaimModal from './ClaimModal';
import { MenuItem } from '../../../../components/PopUpMenu/PopUpMenu';
import { MenuOption } from '../common/types';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import * as S from '../AcvList.styled';

interface Props {
  claims: Claim[];
  isFormModalOpen: boolean;
  isConfirmModalOpen: boolean;
  isLoading: boolean;
  onOpenFormModal: () => void;
  onOpenConfirmModal: () => void;
  onClose: () => void;
  onCreate: (claim: Claim) => void;
  onEdit: (
    oldClaim: Claim,
    newClaim: Claim,
    successCallback: () => void,
  ) => void;
  onDelete: (claim: Claim, successCallback: () => void) => void;
  error?: Error;
}

const Claims: React.FC<Props> = ({
  claims,
  isFormModalOpen,
  isConfirmModalOpen,
  isLoading,
  onOpenFormModal,
  onOpenConfirmModal,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  error,
}) => {
  const [activeClaim, setActiveClaim] = useState<Claim | undefined>();

  const onMenuClick = (menuItem: MenuItem, claim: Claim) => {
    setActiveClaim(claim);

    if (menuItem.type === MenuOption.Edit) {
      onOpenFormModal();
    } else if (menuItem.type === MenuOption.Delete) {
      onOpenConfirmModal();
    }
  };

  const onSave = (claim: Claim) => {
    if (activeClaim) {
      onEdit(activeClaim, claim, () => setActiveClaim(undefined));
    } else {
      onCreate(claim);
    }
  };

  const onConfirmDelete = () => {
    if (!activeClaim) {
      return;
    }

    onDelete(activeClaim, () => setActiveClaim(undefined));
  };

  const onCloseModal = () => {
    setActiveClaim(undefined);
    onClose();
  };

  return (
    <S.Section>
      <Typography variant="h6" color="primary">
        Claims
      </Typography>
      <Table
        disableGutters
        disableElevation
        disablePagination
        columns={columnDefs}
        data={sortByYear(claims)}
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        rowTemplate={({ rowIndex: _, ...rest }) => (
          <RowTemplate {...rest} onMenuClick={onMenuClick} />
        )}
        noDataMessage="No claims"
      />
      <ClaimModal
        activeClaim={activeClaim}
        isOpen={isFormModalOpen}
        isLoading={isLoading}
        error={error}
        onSave={onSave}
        onClose={onCloseModal}
      />
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        isLoading={isLoading}
        confirmButtonText="Remove Claim"
        text="Please confirm you would like to remove this claim from the driving history."
        type="deleting"
        errorMessage={error?.message}
        onConfirm={onConfirmDelete}
        onCancel={onCloseModal}
      />
    </S.Section>
  );
};

export default Claims;
