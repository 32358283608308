import Loading from '@componentsV2/Loading';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

const ComponentLoading: React.FC<Props> = ({ className }) => (
  <div
    className={twMerge(
      'flex w-full items-center justify-center p-6',
      className,
    )}
  >
    <Loading color="#162d8b" className="w-6 h-6" />
  </div>
);

export default ComponentLoading;
