import { createMuiTheme } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';
import { getCurrentEnv, Environment } from '../../config';

export const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 0;

    @font-face {
      font-family: 'CircularStd-Black';
      src: url('/fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Black.otf')  format('opentype'),
          url('/fonts/CircularStd-Black.woff') format('woff'), url('/fonts/CircularStd-Black.ttf')  format('truetype'), url('/fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: 'CircularStd-Bold';
      src: url('/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Bold.otf')  format('opentype'),
          url('/fonts/CircularStd-Bold.woff') format('woff'), url('/fonts/CircularStd-Bold.ttf')  format('truetype'), url('/fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: 'CircularStd-Book';
      src: url('/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Book.otf')  format('opentype'),
          url('/fonts/CircularStd-Book.woff') format('woff'), url('/fonts/CircularStd-Book.ttf')  format('truetype'), url('/fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: 'CircularStd-BoldItalic';
      src: url('/fonts/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-BoldItalic.otf')  format('opentype'),
          url('/fonts/CircularStd-BoldItalic.woff') format('woff'), url('/fonts/CircularStd-BoldItalic.ttf')  format('truetype'), url('/fonts/CircularStd-BoldItalic.svg#CircularStd-BoldItalic') format('svg');
      font-weight: normal;
      font-style: normal;
    }
  }

  #root {
    height: 100%;
    background-color: #f3f4fa;
  }

  h1, h2, h3, h4 {
    color: #3632c0;
  }
`;

const mainColour =
  getCurrentEnv() === Environment.Production ? '#031767' : '#31A6C3';

export const theme = {
  fonts: {
    headline: 'CircularStd-Bold',
  },
  palette: {
    background: {
      white: '#ffffff',
    },
    primary: {
      paleLilac: '#e1e1ff',
      bluePurple: '#3632c0',
      contrastText: '#ffffff',
      dark: mainColour,
      lavendarBlue: '#7f81ff',
      main: mainColour,
      grey: '#848ea6',
      lightgrey: '#c0c0c0',
      green: '#0ab98c',
      veryDarkGrey: '#4b4f60',
      paleGrey: '#f3f4fa',
      red: '#ff5c5c',
      black: '#141127',
      lighterGrey: '#b7becf',
      darkGrey: '#d4d9e8',
      white: '#ffffff',
      warning: '#F68721',
    },
    secondary: {
      contrastText: '#ffffff',
      dark: '#1763a9',
      main: '#24aae4',
    },
    shades: {
      boxBackground: 'rgba(0, 0, 0, 0.1)',
      selectedRow: 'rgba(36,170,238, 0.3)',
      primaryRow: 'rgba(36,170,238, 0.1)',
      inactiveRow: 'rgba(173,172,174, 0.5)',
    },
  },
  typography: {
    body1: {
      color: '#4b4f60',
      fontFamily: 'Roboto',
      fontSize: 16,
    },
    h2: {
      fontFamily: 'CircularStd-Bold',
    },
    h4: {
      color: '#4b4f60',
      fontSize: 22,
    },
    h5: {
      fontFamily: 'CircularStd-Book',
    },
    useNextVariants: true,
  },
  font: {
    size: {
      s: '12px',
      m: '16px',
      l: '22px',
    },
    family: {
      circular: 'CircularStd-Book',
      circularBold: 'CircularStd-Bold',
      roboto: 'Roboto',
    },
  },
  spacer: {
    xs: '0.5rem',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
  },
  borderRadius: {
    m: '4px',
  },
};

export const muiTheme = createMuiTheme(theme as any);
