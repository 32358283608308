import { Interval } from 'luxon';
import { GetUserPolicyListQuery } from '@graphql/types';
import { sortPoliciesByDate } from '@helpers/policyHelpers';
import { UserPolicy } from './types';
import { PolicyStatus } from './constants';

const getUserPolicyListSelector = ({
  user,
}: GetUserPolicyListQuery): UserPolicy[] | undefined => {
  if (user.policies.length === 0) {
    return undefined;
  }

  const userPolicies = sortPoliciesByDate(user.policies);

  return userPolicies.map(policy => {
    const { start, end } = Interval.fromISO(policy.term, { setZone: true });

    return {
      id: policy.id,
      policyNumber: policy.policyNumberDetails.value,
      packageName: policy.quoteV2.selectedPackage?.content.packageName ?? '',
      policyholder: {
        name: `${policy.policyholder.name.firstName} ${policy.policyholder.name.lastName}`,
        address: {
          line1: policy.policyholder.address.addressLine1,
          line2: policy.policyholder.address.addressLine2 ?? null,
          city: policy.policyholder.address.city,
          state: policy.policyholder.address.state,
          zipCode: policy.policyholder.address.zipCode,
        },
      },
      status: PolicyStatus.fromApi(policy.status),
      term: {
        start,
        end,
      },
      vehicles: policy.vehicles.map(
        ({ vehicleDetails: { year, make, model, vin } }) => ({
          year,
          make,
          model,
          vin,
        }),
      ),
      premium: {
        perDay: policy.totalPremium.perDay ?? 0,
        perMile: policy.totalPremium.perMile,
      },
    };
  });
};

export default getUserPolicyListSelector;
