import { PolicyStatus as ApiPolicyStatus } from '@graphql/types';

export enum PolicyStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Future = 'Future',
  Void = 'Void',
}

export namespace PolicyStatus {
  export function fromApi(apiPolicyStatus: ApiPolicyStatus): PolicyStatus {
    switch (apiPolicyStatus) {
      case ApiPolicyStatus.Active:
        return PolicyStatus.Active;
      case ApiPolicyStatus.Cancelled:
        return PolicyStatus.Cancelled;
      case ApiPolicyStatus.Expired:
        return PolicyStatus.Expired;
      case ApiPolicyStatus.Future:
        return PolicyStatus.Future;
      case ApiPolicyStatus.Void:
        return PolicyStatus.Void;
    }
  }

  export function toString(policyStatus: PolicyStatus): string {
    switch (policyStatus) {
      case PolicyStatus.Active:
        return 'Active';
      case PolicyStatus.Cancelled:
        return 'Cancelled';
      case PolicyStatus.Expired:
        return 'Expired';
      case PolicyStatus.Future:
        return 'Starting soon';
      case PolicyStatus.Void:
        return 'Voided';
    }
  }
}
