import { UserPolicy } from './types';
import Policy from './Policy';

type Props = {
  userId: string;
  policies: UserPolicy[];
  onPolicyChange: () => void;
};

const PolicyListContent: React.FC<Props> = ({
  userId,
  policies,
  onPolicyChange,
}) => (
  <>
    {policies.map(policy => (
      <Policy
        policy={policy}
        userId={userId}
        key={policy.policyNumber}
        className="mb-6 last:mb-0"
        onPolicyChange={onPolicyChange}
      />
    ))}
  </>
);

export default PolicyListContent;
