import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { H2 } from './Typography';

type Props = {
  title: string;
  open?: boolean;
  className?: string;
};

const SectionToggle: React.FC<Props> = ({
  title,
  open = true,
  className,
  children,
}) => (
  <Disclosure as="div" className={className} defaultOpen={open}>
    <dt>
      <DisclosureButton className="group flex w-full items-center text-left">
        <H2>{title}</H2>
        <span className="ml-2 flex h-4 items-center">
          <ChevronDownIcon
            aria-hidden="true"
            className="h-4 w-4 group-data-[open]:rotate-180 text-blue"
          />
        </span>
      </DisclosureButton>
    </dt>
    <DisclosurePanel
      transition
      as="dd"
      className="transition-all ease-in-out overflow-hidden max-h-screen data-[closed]:opacity:0 data-[closed]:max-h-0 data-[enter]:duration-300 data-[leave]:duration-100"
    >
      <div className="pt-2">{children}</div>
    </DisclosurePanel>
  </Disclosure>
);

export default SectionToggle;
