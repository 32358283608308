import { AccountState } from '@pagesV2/UserPage/types';

export const mapAccountStateName = (accountState: AccountState): string => {
  switch (accountState) {
    case 'Active':
      return 'Active';
    case 'FuturePolicy':
      return 'Starting soon';
    case 'Lapsed':
      return 'Lapsed';
    case 'Registration':
      return 'Registration';
    case 'Suspended':
      return 'Suspended';
  }
};
