import { AccountState } from '@pagesV2/UserPage/types';
import { useGetUserOverviewQuery } from '@graphql/api';
import ComponentLoading from '@componentsV2/ComponentLoading';
import { ErrorMessage } from '@componentsV2/Typography';
import OverviewContent from './OverviewContent';
import getUserOverviewSelector from './getUserOverviewSelector';

type Props = {
  userId: string;
  accountState: AccountState;
};

const Overview: React.FC<Props> = ({ userId, accountState }) => {
  const { isLoading, data: userOverview } = useGetUserOverviewQuery(
    { userId },
    {
      queryKey: ['userOverview', userId],
      select: userOverview =>
        getUserOverviewSelector(userOverview, accountState),
    },
  );

  return (
    <div className="min-h-[170px]">
      {(() => {
        switch (true) {
          case isLoading:
            return <ComponentLoading />;
          case !userOverview:
            return <ErrorMessage>Error loading user overview</ErrorMessage>;
          default:
            return <OverviewContent userOverview={userOverview!} />;
        }
      })()}
    </div>
  );
};

export default Overview;
