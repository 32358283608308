import React, { useState } from 'react';
import UserDetailsCtx from './useUserDetails';
import { Section } from './types';

const UserDetailsController: React.FC = ({ children }) => {
  const [currentSection] = useState<Section>('Policies');
  const [drawerOpen, setDrawerOpen] = useState(true);

  return (
    <UserDetailsCtx.Provider
      value={{
        currentSection,
        drawerOpen,
        toggleDrawer: () => setDrawerOpen(open => !open),
      }}
    >
      {children}
    </UserDetailsCtx.Provider>
  );
};

export default UserDetailsController;
