import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }, ref) => (
    <input
      className={twMerge(
        'py-2 pl-2 pr-10 rounded-lg border border-light-grey w-full text-sm text-slate',
        className,
      )}
      ref={ref}
      {...props}
    />
  ),
);

export default Input;
