export const drawerPortalElementId = 'drawer-portal-anchor';

let element: HTMLElement | null = null;
export function getDrawerPortalElement(): HTMLElement | null {
  if (element) {
    return element;
  }

  element = document.getElementById(drawerPortalElementId) || null;
  return element;
}
