import { useState, ClipboardEvent } from 'react';
import {
  useController,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import Calendar from '@componentsV2/Calendar';
import { PatternFormat, patternFormatter } from 'react-number-format';
import { twMerge } from 'tailwind-merge';
import { DateTime } from 'luxon';
import ErrorMessage from '@componentsV2/Inputs/ErrorMessage';
import Label from '@componentsV2/Inputs/Label';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  placeholder?: string;
  dateFormat?: string;
  showCalendar?: boolean;
  calendarStartDate?: DateTime;
  calendarEndDate?: DateTime;
  className?: string;
};

const DateInput = <T extends FieldValues>({
  label,
  placeholder = 'MM/DD/YYYY',
  dateFormat = 'MM/dd/yyyy',
  showCalendar = false,
  calendarStartDate,
  calendarEndDate,
  className,
  ...props
}: Props<T>) => {
  const { field, fieldState } = useController(props);
  const fieldValue: DateTime | undefined = field.value; // add type to field value
  const [inputValue, setInputValue] = useState(
    fieldValue?.toFormat(dateFormat) ?? '',
  ); // UI value
  const [calendarOpen, setCalendarOpen] = useState(false);

  const inputFormat = dateFormat.replace(/[a-zA-Z]/g, '#');

  return (
    <div className={className}>
      {label ? (
        <div className="mb-1">
          <Label htmlFor={field.name}>{label}</Label>
        </div>
      ) : null}
      <div className="relative">
        <PatternFormat
          disabled={field.disabled}
          mask="_"
          format={inputFormat}
          placeholder={placeholder}
          className={twMerge(
            'py-2 pl-2 pr-10 rounded-lg border border-light-grey w-full text-sm text-slate',
            fieldState.error ? 'border-red' : '',
          )}
          onValueChange={({ formattedValue }) => {
            setInputValue(formattedValue);
            field.onChange(DateTime.fromFormat(formattedValue, dateFormat));
          }}
          onBlur={field.onBlur}
          value={inputValue}
          onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            const parsedDate = e.clipboardData
              .getData('text')
              .replace(/\D/g, '')
              .slice(0, 8);
            const formattedDate = patternFormatter(parsedDate, {
              format: inputFormat,
            });
            setInputValue(formattedDate);
            field.onChange(DateTime.fromFormat(formattedDate, dateFormat));
          }}
          name={field.name}
          getInputRef={field.ref}
        />
        <button
          className="absolute top-0 right-2 h-full flex items-center justify-center"
          type="button"
          onClick={() => setCalendarOpen(open => !open)}
        >
          {showCalendar ? (
            <CalendarDaysIcon className="w-5 h-5 text-slate" />
          ) : null}
        </button>
      </div>
      {fieldState.error ? (
        <ErrorMessage message={fieldState.error.message} />
      ) : null}
      {calendarOpen ? (
        <Calendar
          selectedDate={fieldValue}
          onChange={date => {
            setInputValue(date.toFormat(dateFormat));
            field.onChange(date);
            setCalendarOpen(false);
          }}
          startDate={calendarStartDate}
          endDate={calendarEndDate}
          className="mx-auto mt-4"
        />
      ) : null}
    </div>
  );
};

export default DateInput;
