import { AccountState } from '@pagesV2/UserPage/types';
import Overview from './Overview';
import PolicyList from './PolicyList';

type Props = {
  userId: string;
  accountState: AccountState;
};

const Policies: React.FC<Props> = ({ userId, accountState }) => (
  <>
    <Overview userId={userId} accountState={accountState} />
    <PolicyList userId={userId} className="mt-10" />
  </>
);

export default Policies;
