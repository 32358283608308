import React, { Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PageLoading from '@componentsV2/PageLoading';
import { Feature, isFeatureEnabled } from '@config';
import ErrorPage from '@pagesV2/ErrorPage';
import UserPage from '@pagesV2/UserPage';
import { Destinations } from '@destinationsV2';
import SideDrawerProvider from '@componentsV2/SideDrawer/SideDrawerProvider';

const Portal: React.FC = () => (
  <SideDrawerProvider>
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {isFeatureEnabled(Feature.UserPageV2) ? (
          <Route path={Destinations.User} exact component={UserPage} />
        ) : null}
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  </SideDrawerProvider>
);

export default withRouter(Portal);
