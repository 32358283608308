import { EditButton } from '../PolicyCard/Buttons';
import PolicyCardOptions from '../PolicyCard/PolicyCardOptions';

type Props = {
  onChangeStartDate: () => void;
};

const PolicyOptions: React.FC<Props> = ({ onChangeStartDate }) => {
  return (
    <PolicyCardOptions>
      <EditButton onClick={onChangeStartDate}>Change start date</EditButton>
    </PolicyCardOptions>
  );
};

export default PolicyOptions;
