import { twMerge } from 'tailwind-merge';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'alert' | 'info' | 'transparent';
  className?: string;
}

const Button: React.FC<Props> = ({
  children,
  className,
  variant = 'info',
  ...props
}) => (
  <button
    className={twMerge(
      'text-snow px-4 py-2 rounded-md text-sm font-bold cursor-pointer flex items-center justify-center transition-all duration-300 hover:bg-opacity-90 min-w-[140px] disabled:bg-opacity-50',
      variant === 'alert' ? 'bg-red' : '',
      variant === 'info' ? 'bg-teal' : '',
      variant === 'transparent' ? 'bg-transparent text-slate' : '',
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
