import Alert from './Alert';

type Props = {
  status?: 'error' | 'info' | 'success';
  errorMessage?: string;
  infoMessage?: string;
  successMessage?: string;
  className?: string;
};

const StatusAlert: React.FC<Props> = ({
  status,
  errorMessage = 'Error',
  infoMessage = 'Updating...',
  successMessage = 'Success',
  className,
}) => {
  switch (status) {
    case 'info':
      return (
        <Alert variant="info" className={className}>
          {infoMessage}
        </Alert>
      );
    case 'error':
      return (
        <Alert variant="error" className={className}>
          {errorMessage}
        </Alert>
      );
    case 'success':
      return (
        <Alert variant="success" className={className}>
          {successMessage}
        </Alert>
      );
    default:
      return null;
  }
};

export default StatusAlert;
