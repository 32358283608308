import { Transition } from '@headlessui/react';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';

type Props = {
  open: boolean;
  toggleDrawer: () => void;
};

const Drawer: React.FC<Props> = ({ open, toggleDrawer, children }) => (
  <>
    <Transition show={open}>
      <div className="fixed inset-y-0 z-40 flex w-75 flex-col right-0 data-[closed]:opacity-0 transform transition duration-300 data-[closed]:translate-x-full">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-l border-cloudy-blue px-6 pb-4 pt-20">
          <div className="pt-16 pb-10">{children}</div>
        </div>
      </div>
    </Transition>
    <button
      type="button"
      onClick={() => toggleDrawer()}
      className="text-cloudy-blue p-2.5 absolute top-20 right-2 sm:right-4 cursor-pointer lg:z-50"
    >
      <span className="sr-only">Open drawer</span>
      <ArrowLeftStartOnRectangleIcon aria-hidden="true" className="h-6 w-6" />
    </button>
  </>
);

export default Drawer;
