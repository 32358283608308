import { PolicyStatus } from '../../constants';
import { UserPolicy } from '../../types';
import PolicyCard from '../PolicyCard/PolicyCard';
import PolicyNumber from '../PolicyCard/PolicyNumber';
import PolicyCardHeader from '../PolicyCard/PolicyCardHeader';
import PolicyCardContent from '../PolicyCard/PolicyCardContent';
import PolicyInfo from '../PolicyCard/PolicyInfo';
import PolicyOptions from './PolicyOptions';
import { useSideDrawer } from '@componentsV2/SideDrawer/useSideDrawer';
import SideDrawer from '@componentsV2/SideDrawer';
import UpdatePolicyStartDate from './Drawers/UpdatePolicyStartDate';

type Props = {
  userId: string;
  policy: UserPolicy;
  onPolicyChange: () => void;
};

const FuturePolicy: React.FC<Props> = ({ userId, policy, onPolicyChange }) => {
  const drawer = useSideDrawer();

  return (
    <>
      <PolicyNumber
        policyNumber={policy.policyNumber}
        policyCardType="future"
        label={PolicyStatus.toString(policy.status)}
        className="mb-3"
      />
      <PolicyCard>
        <PolicyCardHeader policy={policy} policyCardType="future" />
        <PolicyCardContent>
          <PolicyInfo policy={policy} policyCardType="future" />
          <PolicyOptions
            onChangeStartDate={() =>
              drawer.open({ title: 'Change start date' })
            }
          />
        </PolicyCardContent>
      </PolicyCard>
      <SideDrawer id={drawer.id}>
        <UpdatePolicyStartDate
          currentStartDate={policy.term.start}
          userId={userId}
          policyId={policy.id}
          onClose={() => drawer.close()}
          onSuccess={onPolicyChange}
        />
      </SideDrawer>
    </>
  );
};

export default FuturePolicy;
