import { AccountState } from '@pagesV2/UserPage/types';
import UserDetailsController from './UserDetailsController';
import UserPage from './components/UserPage';

type Props = {
  userId: string;
  accountState: AccountState;
};

const ActiveUserPage: React.FC<Props> = ({ userId, accountState }) => (
  <UserDetailsController>
    <UserPage userId={userId} accountState={accountState} />
  </UserDetailsController>
);

export default ActiveUserPage;
