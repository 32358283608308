import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';

type Variant = 'success' | 'info' | 'error';

type Props = {
  variant?: Variant;
  className?: string;
};

const Alert: React.FC<Props> = ({ variant = 'info', className, children }) => (
  <div
    className={twMerge(
      'rounded-lg p-3',
      variant === 'success' ? 'bg-[#f0fdf4]' : '',
      variant === 'info' ? 'bg-[#eff6ff]' : '',
      variant === 'error' ? 'bg-[#fef2f2]' : '',
      className,
    )}
  >
    <div className="flex">
      <div>
        <AlertIcon variant={variant} />
      </div>
      <div className="ml-2">
        <p
          className={twMerge(
            'text-sm',
            variant === 'success' ? 'text-green' : '',
            variant === 'info' ? 'text-blue' : '',
            variant === 'error' ? 'text-red' : '',
          )}
        >
          {children}
        </p>
      </div>
    </div>
  </div>
);

const AlertIcon: React.FC<{ variant: Variant }> = ({ variant }) => {
  switch (variant) {
    case 'success':
      return (
        <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green" />
      );
    case 'info':
      return (
        <InformationCircleIcon
          aria-hidden="true"
          className="h-5 w-5 text-blue"
        />
      );
    case 'error':
      return <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red" />;
    default:
      return null;
  }
};

export default Alert;
