import { UserOverview } from './types';
import {
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  TruckIcon,
  UserIcon,
  CakeIcon,
  MapIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { H1, H2 } from '@componentsV2/Typography';
import { mapAccountStateName } from './mappers';
import { formatBalance } from '@helpers/formatHelpers';
import { addressToString } from '@helpers/addressHelpers';

type Props = {
  userOverview: UserOverview;
};

const OverviewContent: React.FC<Props> = ({ userOverview }) => {
  const { contact, driving, balance, accountStatus } = userOverview;

  return (
    <div>
      <H1 className="mb-4">{contact.name}</H1>
      <div className="grid grid-cols-4">
        <Column>
          <H2 className="mb-2">Contact overview</H2>
          <OverviewItem Icon={EnvelopeIcon}>{contact.email}</OverviewItem>
          <OverviewItem Icon={PhoneIcon}>
            {contact.phoneNumber ?? 'No phone number'}
          </OverviewItem>
          <OverviewItem Icon={MapPinIcon}>
            {addressToString(contact.address)}
          </OverviewItem>
        </Column>
        <Column>
          <H2 className="mb-2">Driving overview</H2>
          {driving.vehicles.map(({ year, make, model, vin }) => (
            <OverviewItem Icon={TruckIcon} key={vin}>
              {year}, {make}, {model}
            </OverviewItem>
          ))}
          <OverviewItem Icon={UserIcon}>
            {driving.driverLicenseNumber ?? 'No driver license number'}
          </OverviewItem>
          <OverviewItem Icon={CakeIcon}>
            {driving.dob.toFormat('MM/dd/yyyy')}
          </OverviewItem>
          <OverviewItem Icon={MapIcon}>
            Auto trip detection:{' '}
            <span className="font-bold">
              {driving.autoTripEnabled ? 'On' : 'Off'}
            </span>
          </OverviewItem>
        </Column>
        <Column>
          <H2 className="mb-2">Balance</H2>
          <OverviewItem>
            Current balance:{' '}
            <span className="font-bold">
              {formatBalance(balance.currentBalance)}
            </span>
          </OverviewItem>
          <OverviewItem>
            Deposit:{' '}
            <span className="font-bold">{formatBalance(balance.deposit)}</span>
          </OverviewItem>
          <OverviewItem>
            Total funds:{' '}
            <span className="font-bold">
              {formatBalance(balance.totalFunds)}
            </span>
          </OverviewItem>
          <OverviewItem Icon={CreditCardIcon}>
            Auto pay:{' '}
            <span className="font-bold">
              {balance.autoPayEnabled ? 'On' : 'Off'}
            </span>
          </OverviewItem>
        </Column>
        <Column>
          <H2 className="mb-2">Account status</H2>
          <OverviewItem>
            Status:{' '}
            <span className="font-bold">
              {mapAccountStateName(accountStatus.accountState)}
            </span>
          </OverviewItem>

          <OverviewItem>
            Eligibility:{' '}
            <span className="font-bold">
              {accountStatus.eligible ? 'Insurable' : 'Not insurable'}
            </span>
          </OverviewItem>
          <OverviewItem>
            Blocked:{' '}
            <span className="font-bold">
              {accountStatus.blocked ? 'Yes' : 'No'}
            </span>
          </OverviewItem>
        </Column>
      </div>
    </div>
  );
};

const Column: React.FC = ({ children }) => (
  <div className="pr-2 mr-2 border-r border-light-grey last:border-none last:mr-0">
    {children}
  </div>
);

const OverviewItem: React.FC<{ Icon?: HeroIcon }> = ({ Icon, children }) => (
  <div className="flex items-center mb-2">
    {Icon ? <Icon className="w-4 h-4 text-cloudy-blue mr-1" /> : null}
    <div className="flex-1 text-xs text-slate">{children}</div>
  </div>
);

export default OverviewContent;
