import { twMerge } from 'tailwind-merge';
import { PolicyCardType } from './types';

type Props = {
  policyNumber: string;
  label: string;
  policyCardType: PolicyCardType;
  className?: string;
};

const PolicyNumber: React.FC<Props> = ({
  policyNumber,
  label,
  policyCardType,
  className,
}) => (
  <div className={twMerge('flex items-center', className)}>
    <p className="font-bold text-sm text-slate">#{policyNumber}</p>
    <span
      className={twMerge(
        'ml-3 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1.5 text-xs leading-none',
        policyCardType === 'active' ? 'bg-pale-green text-green' : '',
        policyCardType === 'future' ? 'bg-pale-orange text-orange' : '',
        policyCardType === 'ended' ? 'bg-pale-grey text-slate' : '',
      )}
    >
      <svg
        viewBox="0 0 6 6"
        aria-hidden="true"
        className={twMerge(
          'h-1.5 w-1.5',
          policyCardType === 'active' ? 'fill-green' : '',
          policyCardType === 'future' ? 'fill-orange' : '',
          policyCardType === 'ended' ? 'fill-slate' : '',
        )}
      >
        <circle r={3} cx={3} cy={3} />
      </svg>
      {label}
    </span>
  </div>
);

export default PolicyNumber;
