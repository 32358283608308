import { PolicyStatus } from '../../constants';
import { UserPolicy } from '../../types';
import PolicyCard from '../PolicyCard/PolicyCard';
import PolicyNumber from '../PolicyCard/PolicyNumber';
import PolicyCardHeader from '../PolicyCard/PolicyCardHeader';
import PolicyCardContent from '../PolicyCard/PolicyCardContent';
import PolicyInfo from '../PolicyCard/PolicyInfo';

type Props = {
  policy: UserPolicy;
};

const EndedPolicy: React.FC<Props> = ({ policy }) => {
  return (
    <>
      <PolicyNumber
        policyNumber={policy.policyNumber}
        policyCardType="ended"
        label={PolicyStatus.toString(policy.status)}
        className="mb-3"
      />
      <PolicyCard>
        <PolicyCardHeader policy={policy} policyCardType="ended" />
        <PolicyCardContent>
          <PolicyInfo policy={policy} policyCardType="ended" />
        </PolicyCardContent>
      </PolicyCard>
    </>
  );
};

export default EndedPolicy;
