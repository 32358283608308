import { GetUserAccountStateQuery } from '@graphql/types';
import { AccountState } from '../types';

const getAccountStateSelector = ({
  user,
}: GetUserAccountStateQuery): AccountState | null => {
  const accountState = user.underwriting.account?.state;

  switch (accountState?.__typename) {
    case 'ActiveState':
      return 'Active';
    case 'LapsedState':
      return 'Lapsed';
    case 'SuspendedState':
      return 'Suspended';
    case 'RegistrationState': {
      /**
       * Users with a future policy are still classified as in Registration,
       * and only get set as Active once their policy starts.
       * For radr these users we set as `FuturePolicy` so we can manage their upcoming policy.
       */
      if (accountState.upcomingPolicy) return 'FuturePolicy';
      return 'Registration';
    }
    default:
      return null;
  }
};

export default getAccountStateSelector;
