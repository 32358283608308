import Button from '@componentsV2/Button';
import { XCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

type Props = {
  className?: string;
  onClick: () => void;
};

export const CancelButton: React.FC<Props> = ({
  children,
  className,
  onClick,
}) => (
  <Button className={className} variant="alert" onClick={onClick}>
    <XCircleIcon className="w-4 h-4 mr-2" />
    <span>{children}</span>
  </Button>
);

export const EditButton: React.FC<Props> = ({
  children,
  className,
  onClick,
}) => (
  <Button className={className} variant="info" onClick={onClick}>
    <PencilSquareIcon className="w-4 h-4 mr-2" />
    <span>{children}</span>
  </Button>
);

export default CancelButton;
