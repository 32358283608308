import {
  useForm,
  FormProvider,
  FieldValues,
  SubmitHandler,
  DefaultValues,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { ZodSchema } from 'zod';
import React from 'react';

type Props<T extends FieldValues> = {
  defaultValues: DefaultValues<T>;
  schema: ZodSchema;
  onSubmit: SubmitHandler<T>;
  children: React.ReactNode;
};

const Form = <T extends FieldValues>({
  children,
  onSubmit,
  defaultValues,
  schema,
}: Props<T>) => {
  const methods = useForm<T>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default Form;
