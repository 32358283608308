export enum Feature {
  TripsV2 = 'REACT_APP_FEAT_TRIPSV2',
  EditTallyUps = 'REACT_APP_FEAT_EDIT_TALLYUP',
  ManualTransaction = 'REACT_APP_FEAT_MANUAL_TRANSACTION',
  VehicleSelection = 'REACT_APP_VEHICLE_SELECTION',
  Referrals = 'REACT_APP_FEAT_REFERRALS',
  BalanceFinalisation = 'REACT_APP_FEAT_BALANCE_FINALISATION',
  TakePayment = 'REACT_APP_FEAT_TAKE_PAYMENT',
  EditACV = 'REACT_APP_FEAT_EDIT_ACV',
  FeatureFlags = 'REACT_APP_FEATURE_FLAGS',
  AuditTrail = 'REACT_APP_FEAT_AUDIT_TRAIL',
  OdometerUpload = 'REACT_APP_FEAT_ODOMETER_UPLOAD',
  UserPageV2 = 'REACT_APP_FEAT_USER_PAGE_V2',
}

export enum Environment {
  Local = 'local',
  Staging = 'staging',
  Production = 'production',
}

export const getConfig = () => {
  return process.env;
};

export const isFeatureEnabled = (feature: Feature): boolean => {
  const config = getConfig();
  const enabled = config[feature] as string;
  return enabled === 'true';
};

export const getCurrentEnv = (): Environment => {
  const config = getConfig();

  switch (config.REACT_APP_ENVIRONMENT) {
    case 'local':
      return Environment.Local;
    case 'staging':
      return Environment.Staging;
    case 'production':
      return Environment.Production;
  }
};

export const getCurrentVersion = () => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const pckJson = require('../../package.json');
  return pckJson.version;
};

export const isProduction = getCurrentEnv() === Environment.Production;
