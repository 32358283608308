import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  htmlFor?: string;
};

const Label: React.FC<Props> = ({ children, className, htmlFor }) => (
  <label
    className={twMerge('text-sm font-bold text-slate', className)}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export default Label;
