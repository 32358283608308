import { twMerge } from 'tailwind-merge';

type Props = {
  message?: string;
  className?: string;
};

const ErrorMessage: React.FC<Props> = ({ message = 'Error', className }) => {
  return (
    <div className={twMerge('text-red text-sm', className)}>{message}</div>
  );
};

export default ErrorMessage;
