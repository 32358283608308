import { AppBar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Maybe } from '../../../../types/interfaces';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import OdometerImageViewer from '../../../components/OdometerImageViewer/OdometerImageViewer';
import { Table } from '../../../components/Table';
import { sendPageview } from '../../../services';
import { ErrorCode, logError } from '../../../services/logging';
import * as odometerService from '../../../services/repository/odometerService';
import {
  TallyUp,
  TallyUpOdometer,
} from '../../../services/repository/odometerService';
import TallyUpHeader from '../TallyUpHeader/TallyUpHeader';
import ValidateOdometer from './components/ValidateOdometer';
import {
  Container,
  PendingHeader,
  TallyTileContainer,
  UserContent,
} from './TallyUpReading.styled';
import TallyUpReadingRowTemplate from './components/TallyUpReadingRowTemplate';
import { tallyUpColumnDefs } from './components/tallyUpReadingColumnDefs';
import MilesVerified from './components/MilesVerified';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import { OdometerStatus, TallyUpStatus } from '@just-insure/api';
import ErrorModalCustom from '../../../components/ErrorModal/ErrorModalCustom';

interface MatchParams {
  id: string;
  userId: string;
}

type Props = RouteComponentProps<MatchParams>;

const TallyUpReading: React.FC<Props> = ({ location, history, match }) => {
  const [error, setError] = useState<Maybe<AxiosError>>();
  const [selectedReading, setSelectedReading] = useState<TallyUpOdometer>();
  const [tallyUps, setTallyUps] = useState<TallyUp[]>([]);
  const [activeTallyUp, setActiveTallyUp] = useState<TallyUp | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTallyUps, setIsLoadingTallyUps] = useState(false);
  const [diffMileage, setDiffMileage] = useState<number | undefined>();

  const tallyUpId = match.params.id;
  const userId = parseInt(match.params.userId, 10);

  const tallyUpIsRevertible: boolean = !!(
    activeTallyUp &&
    activeTallyUp.id === tallyUps[0]?.id &&
    activeTallyUp.status !== TallyUpStatus.Pending &&
    activeTallyUp.isInitial
  );

  const getAllTallyUps = async () => {
    try {
      setIsLoadingTallyUps(true);
      const tally = await odometerService.getTallyups({
        userId: userId ? `${userId}` : undefined,
      });

      const active = tally.find(t => t.id === tallyUpId);
      setTallyUps(
        [...tally].sort(odometerService.sortTallyUpDateToByNewestToOldest),
      );
      setActiveTallyUp(active || null);
    } catch (ex: any) {
      logError(ErrorCode.TallyUpReading, ex, { userId });
      setError(ex);
    } finally {
      setIsLoadingTallyUps(false);
    }
  };

  const fetchTallyUp = async () => {
    try {
      setLoading(true);
      const currentTallyUp = await odometerService.getTallyUp(tallyUpId);
      if (currentTallyUp) {
        setTallyUps(allTallyUps =>
          allTallyUps.map(tallyUp =>
            tallyUp.id === tallyUpId ? currentTallyUp : tallyUp,
          ),
        );
      }
      setActiveTallyUp(currentTallyUp);
    } catch (ex: any) {
      logError(ErrorCode.TallyUpReading, ex, { userId });
      setError(ex);
    } finally {
      setLoading(false);
    }
  };

  const revertTallyUp = useCallback(async () => {
    if (!activeTallyUp) {
      return;
    }

    try {
      const id = activeTallyUp.id;
      setLoading(true);
      await odometerService.revertTallyUp(id);
      await fetchTallyUp();
    } catch (ex) {
      const err = ex as AxiosError<any>;
      logError(ErrorCode.TallyUpReading, err, {
        userId,
        tallyUpId,
      });

      setError(err);
    } finally {
      setLoading(false);
    }
  }, [activeTallyUp, userId, tallyUpId]);

  useEffect(() => {
    getAllTallyUps();
    sendPageview(location.pathname);
  }, [tallyUpId]);

  if (isLoadingTallyUps) {
    return <LoadingModal isOpen text="Getting latest tally ups..." />;
  }

  const onNavigateToTallyUp = () => {
    history.push('/tallyups');
  };

  if (!activeTallyUp) {
    return (
      <ErrorModalCustom
        error={`Could not find TallyUp with id ${tallyUpId}`}
        onClose={onNavigateToTallyUp}
      />
    );
  }

  const onNavigateToUser = () => {
    history.push(`/users/${activeTallyUp.userId}`);
  };

  const processTallyUp = async (isAccepting: boolean) => {
    try {
      const id = activeTallyUp.id;
      setLoading(true);

      await (isAccepting
        ? odometerService.acceptTallyUp(id)
        : odometerService.rejectTallyUp(id));

      await fetchTallyUp();
    } catch (ex: any) {
      logError(ErrorCode.TallyUpReading, ex, {
        userId,
        tallyUpId,
      });
      setError(ex);
    } finally {
      setLoading(false);
    }
  };

  const processOdometer = async (
    odometerId: string,
    isAccepting: boolean,
    value: number = 0,
  ) => {
    setLoading(true);
    try {
      await (isAccepting
        ? odometerService.patchOdometer(
            odometerId,
            value!,
            OdometerStatus.Accepted,
          )
        : odometerService.patchOdometer(
            odometerId,
            null,
            OdometerStatus.Rejected,
          ));
      await fetchTallyUp();
    } catch (e: any) {
      logError(ErrorCode.TallyUpReading, e, {
        tallyUpId: activeTallyUp.id,
        odometerId,
        userId: `${userId}`,
      });
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const voidTallyUp = async () => {
    try {
      const id = activeTallyUp.id;
      setLoading(true);
      await odometerService.voidTallyUp(id);
      await fetchTallyUp();
    } catch (ex: any) {
      logError(ErrorCode.TallyUpReading, ex, {
        userId,
        tallyUpId,
      });
      setError(ex);
    } finally {
      setLoading(false);
    }
  };

  const startOdo = activeTallyUp.startOdometer;

  const isStartOdoAccepted = startOdo.status !== OdometerStatus.Pending;

  return (
    <Container>
      {activeTallyUp ? (
        <TallyUpHeader
          title={`User ${activeTallyUp!.userId}'s TallyUps`}
          onUserClick={onNavigateToUser}
          onGoBack={() => history.goBack()}
          hideReset
        />
      ) : (
        <Typography variant="h4">Loading...</Typography>
      )}
      <TallyTileContainer>
        {activeTallyUp ? (
          <>
            <ValidateOdometer
              tallyUp={activeTallyUp}
              isRevertible={tallyUpIsRevertible}
              type="start"
              userId={activeTallyUp.userId}
              onImageClick={setSelectedReading}
              onAccept={(id: string, value: number) => {
                processOdometer(id, true, value);
              }}
              onReject={(id: string) => {
                processOdometer(id, false);
              }}
              onRevert={revertTallyUp}
            />
            {activeTallyUp.isInitial ? null : (
              <ValidateOdometer
                tallyUp={activeTallyUp}
                type="end"
                userId={activeTallyUp.userId}
                onImageClick={setSelectedReading}
                onAccept={(id: string, value: number) =>
                  processOdometer(id, true, value)
                }
                onReject={(id: string) => processOdometer(id, false)}
                onValueSet={v => setDiffMileage(v)}
              />
            )}
            {isStartOdoAccepted ? (
              <MilesVerified
                isProcessing={isLoading}
                tallyUp={activeTallyUp}
                onAccept={() => {
                  processTallyUp(true);
                }}
                onReject={() => {
                  processTallyUp(false);
                }}
                onVoid={voidTallyUp}
                currentMilesDiff={diffMileage}
              />
            ) : null}
          </>
        ) : null}
      </TallyTileContainer>
      <UserContent>
        <AppBar position="static">
          <PendingHeader>ODOMETER READINGS</PendingHeader>
        </AppBar>
        <Table
          data={tallyUps}
          total={tallyUps.length}
          columns={tallyUpColumnDefs}
          rowTemplate={p => (
            <TallyUpReadingRowTemplate
              {...p}
              key={p.item.id}
              onImageClick={setSelectedReading}
            />
          )}
        />
      </UserContent>
      {activeTallyUp && selectedReading ? (
        <OdometerImageViewer
          userId={activeTallyUp.userId}
          readingId={parseInt(selectedReading.id, 10)}
          visible
          onClose={() => setSelectedReading(undefined)}
        />
      ) : null}
      <LoadingModal
        isOpen={isLoading}
        text="Updating Odometer, please wait..."
      />
      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </Container>
  );
};

export default withRouter(TallyUpReading);
