import { createContext, useContext } from 'react';
import { Section } from './types';

type UserDetailsContext = {
  currentSection: Section;
  drawerOpen: boolean;
  toggleDrawer: () => void;
};

const UserDetailsCtx = createContext<UserDetailsContext | undefined>(undefined);

export function useUserDetails() {
  const value = useContext(UserDetailsCtx);
  if (value === undefined) {
    throw new Error('useUserDetails needs UserDetailsContext');
  }

  return value;
}

export default UserDetailsCtx;
