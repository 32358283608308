import { DateTime } from 'luxon';
import Form from '@componentsV2/Form/Form';
import DateInput from '@componentsV2/Form/DateInput';
import { schema, FormValues } from './schema';
import { useUpdateFuturePolicyStartDateMutation } from '@graphql/api';
import Label from '@componentsV2/Inputs/Label';
import Input from '@componentsV2/Inputs/Input';
import { logError, ErrorCode } from '@services/logging';
import Button from '@componentsV2/Button';
import StatusAlert from '@componentsV2/StatusAlert';

type Props = {
  policyId: string;
  userId: string;
  currentStartDate: DateTime;
  onClose: () => void;
  onSuccess: () => void;
};

const dateFormat = 'MM/dd/yyyy';

const UpdatePolicyStartDate: React.FC<Props> = ({
  policyId,
  userId,
  currentStartDate,
  onClose,
  onSuccess,
}) => {
  const {
    mutate: updatePolicyStartDate,
    isLoading,
    isError,
    isSuccess,
  } = useUpdateFuturePolicyStartDateMutation({
    onError: (e: Error) => logError(ErrorCode.UpdateFuturePolicyStartDate, e),
    onSuccess: () => onSuccess(),
  });

  return (
    <Form<FormValues>
      defaultValues={{
        startDate: undefined,
      }}
      schema={schema}
      onSubmit={({ startDate }) =>
        updatePolicyStartDate({
          input: {
            policyId,
            userId,
            startDate: startDate.toISODate(),
          },
        })
      }
    >
      <div>
        <Label>Start date</Label>
        <Input
          className="mt-1"
          disabled
          value={currentStartDate.toFormat(dateFormat)}
        />
      </div>
      <DateInput<FormValues>
        name="startDate"
        label="Change to"
        dateFormat={dateFormat}
        showCalendar
        rules={{ required: 'Start date is required' }}
        calendarStartDate={DateTime.now()}
        className="mt-6"
      />
      <div className="flex gap-2 mt-6">
        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
        <Button variant="transparent" onClick={onClose} type="button">
          Cancel
        </Button>
      </div>
      <StatusAlert
        status={
          isLoading
            ? 'info'
            : isError
            ? 'error'
            : isSuccess
            ? 'success'
            : undefined
        }
        infoMessage="Updating policy start date..."
        errorMessage="There was an error updating policy start date."
        successMessage="Successfully updated policy start date."
        className="mt-6"
      />
    </Form>
  );
};

export default UpdatePolicyStartDate;
